<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          @click="onBack()">
          <v-icon>
            mdi-chevron-left
          </v-icon>
          <span>
            ย้อนกลับ
          </span>
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="warning"
          depressed
          :ripple="false"
          style="width: fit-content;"
          @click="onEdit()">
          <v-icon>
            mdi-square-edit-outline
          </v-icon>
          <span>
            แก้ไขข้อมูล
          </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-expansion-panels
      class="mb-4"
      style="gap: 1rem;">
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            สร้างแพ็กเกจ
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <create-package-section
            v-model="formData"
            readonly />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            รอบการเดินทาง
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <period-package-section
            v-model="formData"
            readonly />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            รายละเอียดแพ็กเกจ
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <detail-package-section
            v-model="formData"
            readonly />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            แผนการเดินทาง
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <plan-package-section
            v-model="formData"
            readonly />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            เงื่อนไขและข้อมูลการเดินทาง
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <conditions-package-section
            v-model="formData"
            readonly />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            สถานที่เที่ยวไฮไลท์สำคัญ
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <highlight-package-section
            v-model="formData"
            readonly />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            บริการเพิ่มเติม
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <additional-package-section
            v-model="formData"
            readonly />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            ประวัติการจอง
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <order-histories-section />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="rounded-lg">
        <v-expansion-panel-header>
          <h3 class="primary--text">
            รีวิว
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <reviews-package-section
            :tour-name="formData.name"
            :packages="formData.packages"
            readonly />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import ToursProvider from '@/resources/tours.provider'
import CreatePackageSection from '../components/CreatePackageSection.vue'
import PeriodPackageSection from '../components/PeriodPackageSection.vue'
import DetailPackageSection from '../components/DetailPackageSection.vue'
import PlanPackageSection from '../components/PlanPackageSection.vue'
import ConditionsPackageSection from '../components/ConditionsPackageSection.vue'
import HighlightPackageSection from '../components/HighlightPackageSection.vue'
import AdditionalPackageSection from '../components/AdditionalPackageSection.vue'
import OrderHistoriesSection from '../components/OrderHistoriesSection.vue'
import ReviewsPackageSection from '../components/ReviewsPackageSection.vue'

const ToursService = new ToursProvider()

export default {
  components: {
    CreatePackageSection,
    PeriodPackageSection,
    DetailPackageSection,
    PlanPackageSection,
    ConditionsPackageSection,
    HighlightPackageSection,
    AdditionalPackageSection,
    OrderHistoriesSection,
    ReviewsPackageSection
  },
  data: () => ({
    loading: false,
    formData: {
      name: '',
      description: '',
      type: '',
      imageUrls: [],
      vendor: null,
      duration: {
        days: 1,
        nights: 1
      },
      airline: '',
      accommodation: {
        type: '',
        star: null
      },
      meal: '',
      crews: 0,
      cancellable: false,
      destination: {
        continent: '',
        country: '',
        city: ''
      },
      specialOptions: [''],
      additionalDocuments: [],
      review: {
        rate: 0,
        total: 0
      },
      tags: [],
      galleries: [],
      highlights: [
        {
          title: '',
          description: '',
          imageUrl: []
        }
      ],
      flights: [
        {
          date: '',
          flightNo: '',
          seatMaps: [],
          departure: {
            location: '',
            time: ''
          },
          arrival: {
            location: '',
            time: ''
          }
        }
      ],
      plans: [
        {
          day: '',
          locations: [''],
          contents: [
            {
              time: '',
              icon: '',
              separate: false,
              content: ''
            }
          ]
        }
      ],
      packages: [
        {
          startDate: '',
          endDate: '',
          adult: {
            price: 0,
            withOutTicket: 0
          },
          kid: {
            price: 0,
            withOutTicket: 0
          }
        }
      ],
      rooms: [
        {
          name: 'พักคู่ (TWIN BED)',
          price: 0,
          capacity: 2
        },
        {
          name: 'พักคู่ (DOUBLE BED)',
          price: 0,
          capacity: 2
        },
        {
          name: 'พัก 3 ท่าน (TRIPLE BED)',
          price: 0,
          capacity: 3
        },
        {
          name: 'พักเดี่ยว (SINGLE BED)',
          price: 0,
          capacity: 1
        }
      ],
      insurances: [
        {
          name: '',
          description: '',
          protections: '',
          price: 0
        }
      ],
      seats: [
        {
          name: '',
          description: '',
          price: 0
        }
      ],
      meals: [],
      additionalRateConditions: {
        th: '',
        en: ''
      },
      additionalRateIncludes: [''],
      additionalRateNotIncludes: [''],
      cancelConditions: {
        th: '',
        en: ''
      },
      accommodationInformation: {
        th: '',
        en: ''
      },
      airlineInformation: {
        th: '',
        en: ''
      },
      additionalConditions: {
        th: '',
        en: ''
      },
      status: 'available'
    }
  }),
  async mounted () {
    this.getItemById()
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'TourList' })
    },
    onEdit () {
      this.$router.push({
        name: 'TourEdit',
        params: {
          id: this.$route.params.id
        }
      })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await ToursService.getItemById(this.$route.params.id)

        const tmpForm = {
          ...this.formData,
          ...data
        }

        this.formData = {
          ...tmpForm,
          imageUrls: tmpForm.imageUrls.map((img) => ({
            file: null,
            preview: img,
            mediaUrl: img
          })),
          galleries: tmpForm.galleries.map((img) => ({
            file: null,
            preview: img,
            mediaUrl: img
          })),
          highlights: tmpForm.highlights.map((h) => ({
            ...h,
            imageUrl: h.imageUrl ? [{
              file: null,
              preview: h.imageUrl,
              mediaUrl: h.imageUrl
            }] : []
          })),
          flights: tmpForm.flights.map((f) => ({
            ...f,
            seatMaps: f.seatMaps.map((img) => ({
              file: null,
              preview: img,
              mediaUrl: img
            }))
          }))
        }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>

<style>
.v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}
</style>
